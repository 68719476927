const durationDelta = 0.62;

const defaultTextAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "10rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.75,
  },
};

const defaultImageAnimation = {
  style: {
    position: "relative",
    width: "150px",
    height: "357px",
  },
  initial: {
    opacity: 0,
    right: "-220px",
  },
  animate: {
    opacity: 1,
    right: 0,
  },
  transition: {
    duration: 0.75,
  },
};

const headingAnimation = JSON.parse(JSON.stringify(defaultTextAnimation));
headingAnimation.transition.delay = durationDelta;

const infoAnimation = JSON.parse(JSON.stringify(defaultTextAnimation));
infoAnimation.transition.delay = 2 * durationDelta;

const imageAnimation = JSON.parse(JSON.stringify(defaultImageAnimation));
imageAnimation.transition.delay = 3 * durationDelta;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  heading: headingAnimation,
  info: infoAnimation,
  image: imageAnimation,
};
