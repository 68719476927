import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

const justifyContent = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
};

function ContentTitle({
  spaces,
  titleAlign,
  title,
  titleBold,
  titleLight,
  order,
  keepOrder,
  color,
  children,
  smallFont,
}) {
  return (
    <Box width="100%">
      <Heading
        display="flex"
        flexDirection={
          keepOrder
            ? order === "break"
              ? "inline"
              : "row"
            : {
                base: "column",
                md: order === "break" ? "column" : "row",
              }
        }
        width="100%"
        marginBottom={spaces}
        textAlign={{ base: "center", sm: "left" }}
        justifyContent={justifyContent[titleAlign]}
      >
        {(title || titleBold) && (
          <Text
            as="span"
            color={color}
            fontWeight="bold"
            // lineHeight={{ base: "3.5xl", md: "4xl" }}
            lineHeight={{ base: "fs-2xl", md: smallFont ? "fs-2xl" : "fs-3xl" }}
            fontSize={{ base: "1.5xl", md: smallFont ? "2xl" : "3xl" }}
          >
            {title || titleBold}
          </Text>
        )}
        {titleLight && (
          <>
            <Text
              as="span"
              color={color}
              fontWeight="light"
              // lineHeight={{ base: "3.5xl", md: "4xl" }}
              lineHeight={{
                base: "fs-2xl",
                md: smallFont ? "fs-2xl" : "fs-3xl",
              }}
              fontSize={{ base: "1.5xl", md: smallFont ? "2xl" : "3xl" }}
            >
              <Text
                as="span"
                display={
                  keepOrder
                    ? order === "break"
                      ? "none"
                      : "inline-block"
                    : {
                        base: "none",
                        md: order === "break" ? "none" : "inline-block",
                      }
                }
              >
                &nbsp;
              </Text>
              {titleLight}
            </Text>
          </>
        )}
      </Heading>
      {children && (
        <Text
          lineHeight="7"
          fontSize={{ base: "2sm", md: "md" }}
          fontWeight="light"
          margin="0"
          color={color}
          textAlign={{ base: "center", sm: "left" }}
        >
          {children}
        </Text>
      )}
    </Box>
  );
}

ContentTitle.defaultProps = {
  titleAlign: "left",
  spaces: "3xl",
  order: "break",
  color: "dark.100",
  keepOrder: false,
  smallFont: false,
};

export default ContentTitle;
