import React from "react";
import { Flex, Box, Heading, Text, Img } from "@chakra-ui/react";
import { useTranslation } from "../../core/hooks/useTranslation";
import PageParallax from "../../components/PageParallax";
import PageContainer from "../../components/PageContainer";
import { AnimatedContent } from "../../components/RouteLink";
import SectionAnimation from "../../components/SectionAnimation";
import imgLogo from "../../images/investment-icon.svg";
import animations from "./_animation";

function PageHero({
  backgroundImage,
  titleBold,
  titleLight,
  order,
  isLarge,
  keepOrder,
  smallOnMobileAm,
  children,
}) {
  const { language } = useTranslation();

  return (
    <AnimatedContent>
      <Box
        backgroundColor="dark.200"
        backgroundImage={backgroundImage}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        borderBottom="10px solid"
        borderColor="blue.600"
        overflow="hidden"
        position="relative"
      >
        {backgroundImage && (
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            backgroundColor="dark.200"
            opacity={0.6}
          />
        )}

        <PageContainer>
          <Flex
            minHeight={isLarge ? "10xl" : "10xl-s"}
            width="100%"
            maxWidth={{ base: "100%", md: "80%" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box minWidth="100%">
              <Box minWidth="100%">
                <SectionAnimation {...animations.heading}>
                  <Flex
                    flexDirection={
                      keepOrder
                        ? order === "break"
                          ? "column"
                          : "row"
                        : {
                            base: "column",
                            md: order === "break" ? "column" : "row",
                          }
                    }
                  >
                    <Heading
                      color="white"
                      // lineHeight={{ base: "4xl", md: "5xl" }}
                      lineHeight={
                        "hy-AM" === language
                          ? { base: "fs-2xl-am", md: "fs-5xl-am" }
                          : { base: "fs-2xl", md: "fs-5xl" }
                      }
                      fontSize={{
                        base:
                          smallOnMobileAm && "hy-AM" === language
                            ? "1.9xl"
                            : "2xl",
                        md: "5xl",
                      }}
                      fontWeight="bold"
                      margin="0"
                    >
                      {titleBold}
                    </Heading>
                    {titleLight && (
                      <Heading
                        color="white"
                        // lineHeight={{ base: "4xl", md: "5xl" }}
                        lineHeight={
                          "hy-AM" === language
                            ? { base: "fs-2xl-am", md: "fs-5xl-am" }
                            : { base: "fs-2xl", md: "fs-5xl" }
                        }
                        fontSize={{
                          base:
                            smallOnMobileAm && "hy-AM" === language
                              ? "1.9xl"
                              : "2xl",
                          md: "5xl",
                        }}
                        fontWeight="light"
                      >
                        <Text
                          as="span"
                          display={
                            keepOrder
                              ? order === "break"
                                ? "none"
                                : "inline-block"
                              : {
                                  base: "none",
                                  md:
                                    order === "break" ? "none" : "inline-block",
                                }
                          }
                        >
                          &nbsp;
                        </Text>
                        {titleLight}
                      </Heading>
                    )}
                  </Flex>
                </SectionAnimation>
              </Box>
              <Box marginTop="2xl">
                <SectionAnimation {...animations.info}>
                  <Text as="span" color="white" lineHeight="3xl" fontSize="2sm">
                    {children}
                  </Text>
                </SectionAnimation>
              </Box>
            </Box>

            <PageParallax y={["-150px", "75px"]}>
              <SectionAnimation {...animations.image}>
                <Box overflow="hidden" display={{ base: "none", md: "block" }}>
                  <Img
                    // marginTop="3.2xl"
                    draggable={false}
                    position="absolute"
                    top="0"
                    right="0"
                    src={imgLogo}
                    width="heroImageW"
                    filter="grayscale(1)"
                    // opacity="0.7"
                  />
                </Box>
              </SectionAnimation>
            </PageParallax>
          </Flex>
        </PageContainer>
      </Box>
    </AnimatedContent>
  );
}

PageHero.defaultProps = {
  order: "break",
  keepOrder: false,
  isLarge: false,
  smallOnMobileAm: false,
};

export default PageHero;
