import React, { useMemo } from "react";
import { Parallax } from "react-scroll-parallax";

function PageParallax({ y, children }) {
  const isMobile = useMemo(() => {
    if ("undefined" !== typeof window) {
      return window.innerWidth < 567;
    }

    return false;
  }, []);

  if (isMobile) {
    return children;
  }

  return <Parallax y={y}>{children}</Parallax>;
}

export default PageParallax;
